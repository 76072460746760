import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import Img from "gatsby-image/withIEPolyfill"
import { Link, useI18next } from "../i18n"
import siteMap from "../sitemap"

import { Icon, Title, Breadcrumbs, Image, ImageData } from "../Layout/Components"
import { Popover } from "antd"
import { DynamicRow, RowData } from "../rows/DynamicRow"

import { Seo, StructuredData } from "../seo"
import { Car, Product, WithContext } from "schema-dts"

type CarData = {
  title: string
  slug: string
  uri: string
  language: {
    slug: string
  }
  seo: {
    title: string
    metaDesc: string
  }
  translations: {
    slug: string
    language: {
      slug: string
    }
  }
  tags: {
    nodes: [
      {
        slug: string
        name: string
      }
    ]
  }
  brands: {
    nodes: [
      {
        name: string
        slug: string
        details: {
          logo: ImageData
        }
      }
    ]
  }
  tecnologies: {
    nodes: [
      {
        name: string
        slug: string
      }
    ]
  }
  segments: {
    nodes: [
      {
        name: string
        slug: string
      }
    ]
  }
  details: {
    uid: string
    subtitle: string
    imageHero: ImageData
    listPrice: {
      min: string
      max: string
    }
    rows: RowData[]
    icons: [
      {
        data: string
        iconType: string
        iconStyle: string
        icon: string
        popupContent: string
        title: string
        withPopup: boolean
      }
    ]
  }
}

export default (props) => {
  const { t } = useI18next()
  const page: CarData = props.data.wordPress.car

  // console.log("Page data: ", page)
  // console.log("Props: ", props)
  // console.log("Articles: ", pressArticles)

  const structuredData: WithContext<Car> = {
    "@context": "https://schema.org",
    "@type": "Car",
    name: page.title,
    image: page.details.imageHero?.imageFile?.publicURL,
    brand: page.brands.nodes[0] ? { "@type": "Brand", name: page.brands.nodes[0].name } : null,
  }

  return (
    <GridLayout>
      <Seo title={page.seo.title} description={page.seo.metaDesc} image={page.details.imageHero?.imageFile?.publicURL} />
      <Breadcrumbs data={[{ to: "/", label: t("Home") }, { to: siteMap.cars.it, label: t("Le auto") }, { label: page.title }]} />
      <StructuredData data={structuredData}>
        <div className="row row--full car car__hero">
          <div className="row row--center row--gap-big">
            <div className="car__hero__left">
              <Title>{page.title}</Title>
              <p>{page.details.subtitle}</p>
            </div>
            <div className="car__hero__right">
              <Image file={page.details.imageHero} type="contain" className="car__hero__right-image" />
            </div>
          </div>
        </div>
        <div className="row row--full ">
          <div className="row row--center car car__icons">
            <div className="col--12 mb-medium">
              <Title type="h2">{t("L'auto in sintesi")}</Title>
              {/* <p className="heading-subtitle">{t("Ecco le caratteristiche principali dell'auto")}</p> */}
            </div>
            <div className="col--12 mb-medium car__icons">
              {page.details.icons?.map((icon, i) => (
                <div className="car__icon" key={i}>
                  <Icon icon={icon} className={"car__icon-icon type-" + icon.iconType} strokeWidth={1} />
                  <div className="car__icon-title" dangerouslySetInnerHTML={{ __html: icon.title }}></div>
                  <div className="car__icon-data">
                    {icon.data}
                    {icon.withPopup ? (
                      <Popover content={<div dangerouslySetInnerHTML={{ __html: icon.popupContent }}></div>} placement="bottom">
                        <div style={{ display: "inline" }}>
                          <Icon name="far:info-circle" className="car__icon-popup" size="xs" />
                        </div>
                      </Popover>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {page.details.rows?.map((row, i) => (
          <DynamicRow data={row} graphQLData={props.data} key={i} />
        ))}
      </StructuredData>
    </GridLayout>
  )
}

export const query = graphql`
  query ($id: ID!, $tags: [String!], $buyingGroupsWpIds: [ID!], $languageCode: Wordpress_LanguageCodeFilterEnum!, $relatedPostsLimit: Int) {
    appsync {
      ...dbBuyingGroupsData
    }
    wordPress {
      car(id: $id) {
        title
        slug
        uri
        language {
          slug
        }
        seo {
          title
          metaDesc
        }
        translations {
          slug
          language {
            slug
          }
        }
        tags {
          nodes {
            slug
            name
          }
        }
        brands {
          nodes {
            name
            slug
            details {
              logo {
                ...imageData
              }
            }
          }
        }
        tecnologies {
          nodes {
            name
            slug
          }
        }
        segments {
          nodes {
            name
            slug
          }
        }
        details {
          uid
          subtitle
          imageHero {
            ...imageData
          }
          listPrice {
            min
            max
          }
          rows {
            ...carsRow
          }
          icons {
            data
            iconType
            iconStyle
            icon
            popupContent
            title
            withPopup
          }
        }
      }
      ...pageAccessoryData
    }
  }
`
